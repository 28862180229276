<template>
  <div>
    <div>
      <school-banner :backgroundImg="proxyBannerImg" :schoolImg="schoolImg" :contactImg="proxyContactImg" :name="universityName" :province="universityProvince" :characteristic="universityCharacteristic" :website="universityWebsite" :phone="universityPhone" :email="universityEmail"></school-banner>
    </div>
    <div class="content-box">
      <div class="content-left">
        <div class="content-title">[{{ majorCode }}]{{ majorName }}</div>
        <header-entry>专业信息</header-entry>
        <div class="content-border content-major">
          <div class="content-major-item">
            <span class="content-major-item-title">所属学校：</span>
            <span class="content-major-item-text">{{ universityName }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">所属学院：</span>
            <span class="content-major-item-text">{{ collegeName }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">招生年份：</span>
            <span class="content-major-item-text">{{ majorEnrollmentYear }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">所属门类：</span>
            <span class="content-major-item-text">{{ majorBelongcategory }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">所属一级学科：</span>
            <span class="content-major-item-text">{{ majorBelongFirstDiscipline }}</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">统考计划招生人数：</span>
            <span class="content-major-item-text">{{ majorPlannedEnrollment }}（不含推免）</span>
          </div>
          <div class="content-major-item">
            <span class="content-major-item-title">特招计划：</span>
            <span class="content-major-item-text">{{ majorSpecialRecruitmentPlan }}</span>
          </div>
        </div>
        <header-entry>考试科目</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item">
            <span class="content-subject-item-title">研究方向</span>
            <span class="content-subject-item-text">{{ majorResearchDirection }}</span>
          </div>
          <div class="content-subject-item">
            <span class="content-subject-item-title">初试科目</span>
            <span class="content-subject-item-text">{{ majorFirstSubject }}</span>
          </div>
          <div class="content-subject-item">
            <span class="content-subject-item-title">复试科目</span>
            <span class="content-subject-item-text">{{ majorSecondSubject }}</span>
          </div>
        </div>
        <template v-if="admitInfoList.length > 0">
          <div class="content-between">
            <header-entry>录取数据</header-entry>
            <div>
              <el-button v-if="admitInfoList.length > 0" style="margin-right: 10px;" type="primary" size="small" @click="onCompareClick">加入对比</el-button>
              <el-button v-if="admitInfoList.length > 0 && isMembership" type="primary" size="small" @click="onShowStudent">生成择校报告</el-button>
            </div>
          </div>
          <div class="content-admit">
            <el-tabs :value="admitInfoList[0].AdmitYear" type="card" class="content-tabs">
              <el-tab-pane class="content-pane" v-for="(item, index) in admitInfoList" :key="index" :label="item.AdmitYear + '年'" :name="item.AdmitYear">
                <div class="content-admit">
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">报考学院：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ collegeName }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">报录比：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitReportRatio }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">总录取人数：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitTotalNumber }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">调剂录取：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitAdjustedNumber }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">一志愿录取：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitVolunteerNumber }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">目标分数：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitTargetScore }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">一志愿最低分：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitVolunteerMinScore }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">一志愿最高分：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitVolunteerMaxScore }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">调剂最低分：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitAdjustMinScore }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                  <div class="content-admit-item">
                    <span class="content-admit-item-title">调剂最高分：</span>
                    <span v-if="isMembership" class="content-admit-item-text">{{ item.AdmitAdjustMaxScore }}</span>
                    <el-image v-else class="content-lock-img" :src="lockIcon" @click="onUnlockClick"></el-image>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </template>
        <template v-if="admitInfoList.length > 0">
          <header-entry>录取名单</header-entry>
          <div class="content-admit">
            <el-tabs :value="admitInfoList[0].AdmitYear" type="card" class="content-tabs">
              <el-tab-pane class="content-pane" v-for="(item, index) in admitInfoList" :key="index" :label="item.AdmitYear + '年'" :name="item.AdmitYear">
                <div v-if="isMembership">
                  <div class="content-admit">
                    <el-image v-if="admitRosterImage(item.AdmitYear)" :src="admitRosterImage(item.AdmitYear)" :preview-src-list="previewAdmitRosterImages(item.AdmitYear)" class="content-admit-img"></el-image>
                  </div>
                  <div class="content-tip">
                    <span>温馨提示: 点击图片可以查看高清原图。</span>
                  </div>
                </div>
                <div v-else class="content-admit">
                  <el-image :src="lockImage2" class="content-admit-img" @click="onUnlockClick"></el-image>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </template>
        <template v-if="adjustDirectionList.length > 0">
          <header-entry>调剂去向</header-entry>
          <div class="content-admit">
            <el-tabs :value="adjustDirectionList[0].AdjustYear" type="card" class="content-tabs">
              <el-tab-pane class="content-pane" v-for="(item, index) in adjustDirectionList" :key="index" :label="item.AdjustYear + '年'" :name="item.AdjustYear">
                <div v-if="isMembership">
                  <div class="content-adjust-title">调剂录取院校-过国家线未被本专业录取考生最终调剂去向学校</div>
                  <div class="content-adjust-text" v-for="(item, index) in adjustDirectionSchools(item.AdjustDirectionSchool)" :key="index">{{ item }}</div>
                  <div class="content-admit">
                    <el-image v-if="adjustRosterImage(item.AdjustYear)" :src="adjustRosterImage(item.AdjustYear)" :preview-src-list="previewAdjustRosterImages(item.AdjustYear)" class="content-admit-img"></el-image>
                  </div>
                  <div class="content-tip">
                    <span>温馨提示: 点击图片可以查看高清原图。</span>
                  </div>
                </div>
                <div v-else>
                  <el-image :src="lockImage3" class="content-admit-img" @click="onUnlockClick"></el-image>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </template>
        <template v-if="admitFileList.length > 0">
          <header-entry>源文件</header-entry>
          <div>
            <el-table border :data="admitFileList" size="small">
              <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
              <el-table-column prop="FileName" label="文件名称"></el-table-column>
              <el-table-column label="下载文件" align="center" width="80">
                <template slot-scope="scope">
                  <el-link v-if="isMembership" class="content-down" type="primary" :href="downSchoolAdmitFileUrl(scope.row)">下载</el-link>
                  <el-link v-else class="content-down" type="primary" @click="onPayment">下载</el-link>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination-box">
              <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
          </div>
        </template>
      </div>
      <div class="content-right">
        <school-hot></school-hot>
      </div>
    </div>

    <el-dialog center destroy-on-close :close-on-click-modal="true" :close-on-press-escape="true" :show-close="true" :visible.sync="studentDialog" title="填写择校报告信息" width="600px">
      <student-dialog1 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog1>
    </el-dialog>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolBanner from "@/components/PC/SchoolBanner";
import SchoolHot from "@/components/PC/SchoolHot";
import StudentDialog1 from "@/components/PC/StudentDialog1";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
  name: "PCModule",
  components: {
    HeaderEntry,
    SchoolBanner,
    SchoolHot,
    StudentDialog1,
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      loading: false,
      studentDialog: false,
      universityCode: null,
      universityName: null,
      universityProvince: null,
      universityWebsite: null,
      universityPhone: null,
      universityEmail: null,
      universityCharacteristic: null,
      collegeCode: null,
      collegeName: null,
      majorCode: null,
      majorName: null,
      majorLearnway: null,
      majorEnrollmentYear: null,
      majorBelongcategory: null,
      majorBelongFirstDiscipline: null,
      majorResearchDirection: null,
      majorSpecialRecruitmentPlan: null,
      majorFirstSubject: null,
      majorSecondSubject: null,
      majorPlannedEnrollment: null,
      isMembership: false,
      admitInfoList: [],
      admitFileList: [],
      adjustDirectionList: [],
      lockIcon: require('@/assets/images/comm/lock.png'),
      lockImage1: require('@/assets/images/comm/admit1.jpg'),
      lockImage2: require('@/assets/images/comm/admit2.jpg'),
      lockImage3: require('@/assets/images/comm/admit3.jpg'),
    };
  },
  computed: {
    schoolImg() {
      if (this.universityCode) {
        return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
      }
    },
    proxyBannerImg() {
      return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=6`;
    },
    proxyContactImg() {
      return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
    },
    admitRosterImage() {
      return function (admitYear) {
        if (this.universityCode && this.collegeCode && this.majorCode && admitYear && this.$store.getters.isLogin) {
          return Config.admit.admitRosterImage + `?UniversityCode=${this.universityCode}&CollegeCode=${this.collegeCode}&MajorCode=${this.majorCode}&AdmitYear=${admitYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    adjustRosterImage() {
      return function (adjustYear) {
        if (this.universityCode && this.collegeCode && this.majorCode && adjustYear && this.$store.getters.isLogin) {
          return Config.admit.adjustRosterImage + `?UniversityCode=${this.universityCode}&CollegeCode=${this.collegeCode}&MajorCode=${this.majorCode}&AdjustYear=${adjustYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    previewAdmitRosterImages() {
      return function (admitYear) {
        return [this.admitRosterImage(admitYear)];
      }
    },
    previewAdjustRosterImages() {
      return function (adjustYear) {
        return [this.adjustRosterImage(adjustYear)];
      }
    },
    adjustDirectionSchools() {
      return function (adjustDirectionSchool) {
        if (adjustDirectionSchool) {
          return adjustDirectionSchool.split('；');
        } else {
          return "";
        }
      }
    },
    downSchoolAdmitFileUrl() {
      return function (row) {
        return `${Config.admit.downloadFile}?FileGuid=${row.FileGuid}&Token=${this.$store.state.token}`;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.universityCode = this.$route.params.universityCode;
      this.collegeCode = this.$route.params.collegeCode;
      this.majorCode = this.$route.params.majorCode;
      this.majorLearnway = this.$route.params.majorLearnway;
    },
    //获取是否购买大数据会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取大学信息
    getUniversityInfo() {
      var model = {
        UniversityCode: this.universityCode,
      };
      this.$axios
        .post(Config.admit.schoolInfo, model)
        .then((res) => {
          this.universityName = res.data.Data.UniversityName;
          this.universityProvince = res.data.Data.UniversityProvince;
          this.universityWebsite = res.data.Data.UniversityWebsite;
          this.universityPhone = res.data.Data.UniversityPhone;
          this.universityEmail = res.data.Data.UniversityEmail;
          this.universityCharacteristic = res.data.Data.UniversityCharacteristic;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取学院信息
    getCollegeInfo() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode
      };
      this.$axios
        .post(Config.admit.collegeInfo, model)
        .then((res) => {
          this.collegeName = res.data.Data.CollegeName;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取专业信息
    getMajorInfo() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        MajorLearningWay: this.majorLearnway,
      };
      this.$axios
        .post(Config.admit.majorInfo, model)
        .then((res) => {
          this.majorName = res.data.Data.MajorName;
          this.majorEnrollmentYear = res.data.Data.MajorEnrollmentYear;
          this.majorBelongcategory = res.data.Data.MajorBelongcategory;
          this.majorBelongFirstDiscipline = res.data.Data.MajorBelongFirstDiscipline;
          this.majorResearchDirection = res.data.Data.MajorResearchDirection;
          this.majorSpecialRecruitmentPlan = res.data.Data.MajorSpecialRecruitmentPlan;
          this.majorFirstSubject = res.data.Data.MajorFirstSubject;
          this.majorSecondSubject = res.data.Data.MajorSecondSubject;
          this.majorPlannedEnrollment = res.data.Data.MajorPlannedEnrollment;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取调剂去向
    getAdjustDirection() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.admit.adjustDirectionList, model)
        .then((res) => {
          this.adjustDirectionList = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数据列表
    getSchoolAdmitDataList() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.admit.admitList, model)
        .then((res) => {
          this.admitInfoList = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录文件列表
    getSchoolAdmitFileList() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {
        UniversityCode: this.universityCode,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      return this.$axios
        .post(Config.admit.fileList, model)
        .then((res) => {
          let data = res.data.Data;
          this.totalCount = data.TotalCount;
          this.admitFileList = data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      if (this.universityName && this.collegeName && this.majorName) {
        Manager.addVisitProductRecord(`报录数据 ${this.universityName}-${this.collegeName}-${this.majorName}`);
      }
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      if (this.universityCode && this.collegeCode && this.majorCode) {
        Manager.addVisitSchoolRecord({
          UniversityCode: this.universityCode,
          CollegeCode: this.collegeCode,
          MajorCode: this.majorCode,
          RecordModule: 7,
        });
      }
    },
    //点击分页
    onCurrentChange() {
      this.getSchoolAdmitFileList();
    },
    //点击跳转购买
    onPayment() {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击加入对比
    onCompareClick() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        MajorLearningWay: this.majorLearnway,
        ProxyCode: this.$store.state.proxyCode,
      };
      this.$axios
        .post(Config.admit.compare.add, model)
        .then((res) => {
          this.$message.success("加入对比成功。");
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //弹窗填写学生报考信息
    onShowStudent() {
      this.studentDialog = true;
    },
    //生成择校报告
    onSubmitReport(formData) {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        //添加报告生成任务
        this.studentDialog = false;
        var model = {
          UniversityCode: this.universityCode,
          CollegeCode: this.collegeCode,
          MajorCode: this.majorCode,
          StudentName: formData.studentName,
          StudentPhone: formData.studentPhone,
          StudentSchool: formData.studentSchool,
          StudentMajor: formData.studentMajor,
          StudentGraded: formData.studentGraded,
          StudentScore: formData.studentScore,
          ProxyCode: this.$store.state.proxyCode,
        };
        this.$axios
          .post(Config.admit.reportGenerate, model)
          .then((res) => {
            var result = res.data.Data;
            if (result.ReportState == 0) {
              this.$alert('报告正在生成中，请到我的资料里面查看生成进度。', '提示');
            } else if (result.ReportState == 1) {
              this.$alert('报告已生成完毕，请到我的资料里面下载。', '提示');
            } else {
              this.$alert('报告未知状态。', '提示');
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //取消生成报告
    onCancelReport() {
      this.studentDialog = false;
    },
    //点击解锁
    onUnlockClick() {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
  },
  mounted() {
    this.getRoutParams();
    this.getIsMembership();
    this.getUniversityInfo();
    this.getCollegeInfo();
    this.getMajorInfo();
    this.getAdjustDirection();
    this.getSchoolAdmitDataList();
    this.getSchoolAdmitFileList();
    this.addVisitSchoolRecord();
  }
};
</script>

<style lang="less" scoped>
.content-box {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.content-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.content-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
}

.content-border {
  border: 1px solid #e6e6e6;
  padding: 10px 30px;
}

.content-major {
  margin: 10px 0;

  .content-major-item {
    display: inline-block;
    width: 50%;
    margin: 10px 0;

    .content-major-item-title {
      color: #555;
      font-size: 14px;
      font-weight: 700;
    }

    .content-major-item-text {
      color: #555;
      font-size: 14px;
    }
  }
}

.content-subject {
  margin: 10px 0;

  .content-subject-item {
    display: flex;
    margin: 20px 0;

    .content-subject-item-title {
      color: #555;
      font-size: 14px;
      font-weight: 700;
    }

    .content-subject-item-text {
      color: #555;
      font-size: 14px;
      margin-left: 20px;
      white-space: pre-wrap;
    }
  }
}

.content-admit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;

  .content-admit-item {
    width: 50%;
    margin: 10px 0;
    display: flex;
    align-items: center;

    .content-admit-item-title {
      display: inline-block;
      color: #555;
      font-size: 14px;
      font-weight: 700;
    }

    .content-admit-item-text {
      color: #555;
      font-size: 14px;
    }
  }

  .content-admit-img {
    cursor: pointer;
    min-height: 50px;
  }
}

.content-tip {
  color: red;
  font-size: 13px;
}

.content-lock-img {
  cursor: pointer;
  height: 15px;
}

.content-adjust-title {
  color: rgb(39, 39, 39);
  font-size: 14px;
  margin-bottom: 10px;
}

.content-adjust-text {
  display: inline-block;
  white-space: nowrap;
  color: gray;
  font-size: 12px;
  margin: 5px 20px 5px 0;
}

.content-tabs {
  width: 100%;
}

.content-pane {
  min-height: 100px;
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.content-down {
  font-size: 12px;
}

.pagination-box {
  text-align: center;
  margin: 10px;
}

::v-deep .el-tabs__header {
  margin: 0px;
}
</style>