<template>
  <div>
    <van-sticky>
      <title-bar2 title="专业详情" />
    </van-sticky>
    <div class="admit-box">
      <div class="admit-content">
        <h3 class="admit-title1">[{{ majorCode }}]{{ majorName }}</h3>
        <div>
          <span class="admit-title2">所属院校</span>
          <span class="admit-text1">{{ universityName }}</span>
        </div>
        <div>
          <span class="admit-title2">所属学院</span>
          <span class="admit-text1">{{ collegeName }}</span>
        </div>
        <div>
          <span class="admit-title2">招生年份</span>
          <span class="admit-text1">{{ majorEnrollmentYear }}</span>
        </div>
        <div>
          <span class="admit-title2">所属门类</span>
          <span class="admit-text1">{{ majorBelongcategory }}</span>
        </div>
        <div>
          <span class="admit-title2">所属一级学科</span>
          <span class="admit-text1">{{ majorBelongFirstDiscipline }}</span>
        </div>
        <div>
          <span class="admit-title2">统考计划招生人数</span>
          <span class="admit-text1">{{ majorPlannedEnrollment }}（不含推免）</span>
        </div>
        <div>
          <span class="admit-title2">特招计划</span>
          <span class="admit-text1">{{ majorSpecialRecruitmentPlan }}</span>
        </div>
      </div>
      <div class="admit-content">
        <h3 class="admit-title1">考试科目</h3>
        <div class="admit-title2">研究方向</div>
        <div class="admit-text1">{{ majorResearchDirection }}</div>
        <div class="admit-title2">初试科目</div>
        <span class="admit-text1">{{ majorFirstSubject }}</span>
        <div class="admit-title2">复试科目</div>
        <span class="admit-text1">{{ majorSecondSubject }}</span>
      </div>
      <div v-if="admitInfoList.length > 0" class="admit-content">
        <div class="admit-between">
          <h3 class="admit-title1">录取数据</h3>
          <div>
            <van-button v-if="admitInfoList.length > 0" round size="small" color="#FE5E03" class="admit-compare" @click.stop="onCompareClick">加入对比</van-button>
            <van-button v-if="admitInfoList.length > 0 && isMembership" round size="small" color="#FE5E03" class="admit-report" @click="onShowStudent">生成择校报告</van-button>
          </div>
        </div>
        <van-swipe autoplay="-" indicator-color="#FE5E03">
          <van-swipe-item v-for="(item, index) in admitInfoList" :key="index">
            <div class="admit-item-box">
              <span class="admit-title2">报考学院</span>
              <span class="admit-text2">{{ collegeName }}</span>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">录取年份</span>
              <span class="admit-text2">{{ item.AdmitYear + '年' }}</span>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">总录取人数</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitTotalNumber }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">一志愿录取</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitVolunteerNumber }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">调剂录取</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitAdjustedNumber }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">一志愿最高分</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitVolunteerMaxScore }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">一志愿最低分</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitVolunteerMinScore }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">调剂最高分</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitAdjustMaxScore }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">调剂最低分</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitAdjustMinScore }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">目标分数</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitTargetScore }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
            <div class="admit-item-box">
              <span class="admit-title2">报录比</span>
              <span v-if="isMembership" class="admit-text2">{{ item.AdmitReportRatio }}</span>
              <van-image v-else class="admit-lock" fit="contain" :src="lockIcon" @click="onUnlockClick"></van-image>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div v-if="admitInfoList.length > 0" class="admit-content">
        <div class="admit-between">
          <h3 class="admit-title1">录取名单</h3>
          <van-button v-if="!isMembership" round size="small" color="#FE5E03" class="admit-unlock" @click="onUnlockClick">解锁</van-button>
        </div>
        <div>
          <van-swipe autoplay="-" indicator-color="#FE5E03">
            <van-swipe-item v-for="(item, index) in admitInfoList" :key="index">
              <div>
                <div class="admit-year">录取年份：{{ item.AdmitYear }}年</div>
                <van-image v-if="isMembership" :src="admitRosterImage(item.AdmitYear)" class="admit-img" @click="onAdmitPreviewClick(item.AdmitYear)">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <van-image v-else :src="lockImage2" class="admit-img" @click="onUnlockClick"></van-image>
              </div>
            </van-swipe-item>
          </van-swipe>
          <div class="admit-tip">
            <span>温馨提示: 点击图片可以查看高清原图。</span>
          </div>
        </div>
      </div>
      <div v-if="adjustDirectionList.length > 0" class="admit-content">
        <div class="admit-between">
          <h3 class="admit-title1">调剂去向</h3>
        </div>
        <div>
          <van-swipe autoplay="-" indicator-color="#FE5E03">
            <van-swipe-item v-for="(item, index) in adjustDirectionList" :key="index">
              <div>
                <div class="admit-year">调剂年份：{{ item.AdjustYear }}年</div>
                <template v-if="isMembership">
                  <div class="adjust-title">调剂录取院校-过国家线未被本专业录取考生最终调剂去向学校</div>
                  <div class="adjust-text" v-for="(item, index) in adjustDirectionSchools(item.AdjustDirectionSchool)" :key="index">{{ item }}</div>
                  <van-image :src="adjustRosterImage(item.AdjustYear)" class="admit-img" @click="onAdjustPreviewClick(item.AdjustYear)">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </template>
                <van-image v-else :src="lockImage3" class="admit-img" @click="onUnlockClick"></van-image>
              </div>
            </van-swipe-item>
          </van-swipe>
          <div class="admit-tip">
            <span>温馨提示: 点击图片可以查看高清原图。</span>
          </div>
        </div>
      </div>
      <div v-if="admitFileList.length > 0" class="admit-content">
        <h3 class="admit-title1">源文件</h3>
        <div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getSchoolAdmitFileList">
            <div v-for="(item, index) in admitFileList" :key="index" class="admit-file-item">
              <span class="admit-file-name">{{ item.FileName }}</span>
              <a v-if="isMembership" :href="downSchoolAdmitFileUrl(item)">
                <van-button hairline type="primary" size="small" class="admit-file-down">下载</van-button>
              </a>
              <van-button v-else hairline type="primary" size="small" class="admit-file-down" @click="onPayment">下载</van-button>
            </div>
          </van-list>
        </div>
      </div>
    </div>

    <van-image-preview closeable :show-index="false" v-model="admitPreview" :images="previewAdmitRosterImages"></van-image-preview>
    <van-image-preview closeable :show-index="false" v-model="adjustPreview" :images="previewAdjustRosterImages"></van-image-preview>

    <van-dialog v-model="studentDialog" :showConfirmButton="false" title="填写择校报告信息">
      <student-dialog1 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog1>
    </van-dialog>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import StudentDialog1 from "@/components/MT/StudentDialog1";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";

export default {
  name: "MTModule",
  components: {
    TitleBar2,
    StudentDialog1,
  },
  data() {
    return {
      index: 0,
      total: 0,
      loading: false,
      finished: false,
      admitPreview: false,
      adjustPreview: false,
      studentDialog: false,
      universityCode: null,
      universityName: null,
      specialRecruitmentPlan: null,
      collegeCode: null,
      collegeName: null,
      majorCode: null,
      majorName: null,
      majorLearnway: null,
      majorEnrollmentYear: null,
      majorBelongcategory: null,
      majorBelongFirstDiscipline: null,
      majorPlannedEnrollment: null,
      majorResearchDirection: null,
      majorSpecialRecruitmentPlan: null,
      majorFirstSubject: null,
      majorSecondSubject: null,
      isMembership: false,
      isUnlocked: false,
      previewAdmitRosterImages: [],
      previewAdjustRosterImages: [],
      admitInfoList: [],
      admitFileList: [],
      adjustDirectionList: [],
      lockIcon: require('@/assets/images/comm/lock.png'),
      lockImage1: require('@/assets/images/comm/admit1.jpg'),
      lockImage2: require('@/assets/images/comm/admit2.jpg'),
      lockImage3: require('@/assets/images/comm/admit3.jpg'),
    };
  },
  computed: {
    schoolImg() {
      if (this.universityCode) {
        return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
      }
    },
    admitRosterImage() {
      return function (admitYear) {
        if (this.universityCode && this.collegeCode && this.majorCode && admitYear && this.$store.getters.isLogin) {
          return Config.admit.admitRosterImage + `?UniversityCode=${this.universityCode}&CollegeCode=${this.collegeCode}&MajorCode=${this.majorCode}&AdmitYear=${admitYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    adjustRosterImage() {
      return function (adjustYear) {
        if (this.universityCode && this.collegeCode && this.majorCode && adjustYear && this.$store.getters.isLogin) {
          return Config.admit.adjustRosterImage + `?UniversityCode=${this.universityCode}&CollegeCode=${this.collegeCode}&MajorCode=${this.majorCode}&AdjustYear=${adjustYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    adjustDirectionSchools() {
      return function (adjustDirectionSchool) {
        if (adjustDirectionSchool) {
          return adjustDirectionSchool.split('；');
        } else {
          return "";
        }
      }
    },
    downSchoolAdmitFileUrl() {
      return function (row) {
        return `${Config.admit.downloadFile}?FileGuid=${row.FileGuid}&Token=${this.$store.state.token}`;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.universityCode = this.$route.params.universityCode;
      this.collegeCode = this.$route.params.collegeCode;
      this.majorCode = this.$route.params.majorCode;
      this.majorLearnway = this.$route.params.majorLearnway;
    },
    //获取是否购买大数据会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取大学信息
    getUniversityInfo() {
      var model = {
        UniversityCode: this.universityCode,
      };
      this.$axios
        .post(Config.admit.schoolInfo, model)
        .then((res) => {
          this.universityName = res.data.Data.UniversityName;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取学院信息
    getCollegeInfo() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode
      };
      this.$axios
        .post(Config.admit.collegeInfo, model)
        .then((res) => {
          this.collegeName = res.data.Data.CollegeName;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取专业信息
    getMajorInfo() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        MajorLearningWay: this.majorLearnway,
      };
      this.$axios
        .post(Config.admit.majorInfo, model)
        .then((res) => {
          this.majorName = res.data.Data.MajorName;
          this.majorEnrollmentYear = res.data.Data.MajorEnrollmentYear;
          this.majorBelongcategory = res.data.Data.MajorBelongcategory;
          this.majorBelongFirstDiscipline = res.data.Data.MajorBelongFirstDiscipline;
          this.majorResearchDirection = res.data.Data.MajorResearchDirection;
          this.majorSpecialRecruitmentPlan = res.data.Data.MajorSpecialRecruitmentPlan;
          this.majorFirstSubject = res.data.Data.MajorFirstSubject;
          this.majorSecondSubject = res.data.Data.MajorSecondSubject;
          this.majorPlannedEnrollment = res.data.Data.MajorPlannedEnrollment;
          this.addVisitProductRecord();
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取调剂去向
    getAdjustDirection() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.admit.adjustDirectionList, model)
        .then((res) => {
          this.adjustDirectionList = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取报录数据列表
    getSchoolAdmitDataList() {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
      };
      return this.$axios
        .post(Config.admit.admitList, model)
        .then((res) => {
          this.admitInfoList = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取报录文件列表
    getSchoolAdmitFileList() {
      var model = {
        UniversityCode: this.universityCode,
        PageIndex: ++this.index,
        PageSize: 20,
      };
      return this.$axios
        .post(Config.admit.fileList, model)
        .then((res) => {
          let data = res.data.Data;
          this.admitFileList.push(...data.List);
          this.loading = false;
          this.total = data.TotalCount;
          if (this.index >= data.PageCount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      if (this.universityName && this.collegeName && this.majorName) {
        Manager.addVisitProductRecord(`报录数据 ${this.universityName}-${this.collegeName}-${this.majorName}`);
      }
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      if (this.universityCode && this.collegeCode && this.majorCode) {
        Manager.addVisitSchoolRecord({
          UniversityCode: this.universityCode,
          CollegeCode: this.collegeCode,
          MajorCode: this.majorCode,
          RecordModule: 7,
        });
      }
    },
    onPayment() {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击加入对比
    onCompareClick(admit) {
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        MajorLearningWay: this.majorLearnway,
        ProxyCode: this.$store.state.proxyCode,
      };
      this.$axios
        .post(Config.admit.compare.add, model)
        .then((res) => {
          this.$toast.success("加入对比成功。");
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //弹窗填写学生报考信息
    onShowStudent() {
      this.studentDialog = true;
    },
    //生成择校报告
    onSubmitReport(formData) {
      this.studentDialog = false;
      //添加报告生成任务
      var model = {
        UniversityCode: this.universityCode,
        CollegeCode: this.collegeCode,
        MajorCode: this.majorCode,
        StudentName: formData.studentName,
        StudentPhone: formData.studentPhone,
        StudentSchool: formData.studentSchool,
        StudentMajor: formData.studentMajor,
        StudentGraded: formData.studentGraded,
        StudentScore: formData.studentScore,
        ProxyCode: this.$store.state.proxyCode,
      };
      this.$axios
        .post(Config.admit.reportGenerate, model)
        .then((res) => {
          var result = res.data.Data;
          if (result.ReportState == 0) {
            this.$dialog({ title: '提示', message: '报告正在生成中，请到我的资料里面查看生成进度。', });
          } else if (result.ReportState == 1) {
            this.$dialog({ title: '提示', message: '报告已生成完毕，请到我的资料里面下载。', });
          } else {
            this.$dialog({ title: '提示', message: '报告未知状态。', });
          }
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //取消生成报告
    onCancelReport() {
      this.studentDialog = false;
    },
    //点击报录名单预览
    onAdmitPreviewClick(admitYear) {
      this.previewAdmitRosterImages = [this.admitRosterImage(admitYear)];
      this.admitPreview = true;
    },
    //点击调剂名单预览
    onAdjustPreviewClick(adjustYear) {
      this.previewAdjustRosterImages = [this.adjustRosterImage(adjustYear)];
      this.adjustPreview = true;
    },
    //点击解锁
    onUnlockClick() {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
  },
  mounted() {
    this.getRoutParams();
    this.getIsMembership();
    this.getUniversityInfo();
    this.getCollegeInfo();
    this.getMajorInfo();
    this.getAdjustDirection();
    this.getSchoolAdmitDataList();
    this.getSchoolAdmitFileList();
    this.addVisitSchoolRecord();
  }
};
</script>

<style scoped lang="less">
.admit-box {
  padding: 0 10px 10px 10px;
}

.admit-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.admit-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admit-item-box {
  display: flex;
  align-items: center;
}

.admit-title1 {
  font-size: 18px;
  font-weight: 500;
}

.admit-title2 {
  display: inline-block;
  width: 130px;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
}

.admit-text1 {
  line-height: 26px;
  color: gray;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.admit-text2 {
  line-height: 26px;
  color: gray;
  font-size: 14px;
  margin: 5px 0;
}

.admit-img {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
  min-height: 50px;
  padding-bottom: 20px;
}

.admit-lock {
  cursor: pointer;
  height: 15px;
}

.admit-unlock {
  padding: 0 10px;
  min-width: 80px;
}

.admit-compare {
  padding: 0 10px;
  min-width: 80px;
}

.admit-report {
  padding: 0 10px;
  min-width: 80px;
  margin-left: 10px;
}

.admit-tip {
  padding-left: 10px;
  color: red;
  font-size: 13px;
}

.adjust-title {
  color: rgb(39, 39, 39);
  font-size: 14px;
  margin: 10px;
}

.adjust-text {
  display: inline-block;
  white-space: nowrap;
  color: gray;
  font-size: 12px;
  margin: 10px;
}

.admit-year {
  margin-left: 10px;
  font-size: 14px;
  color: #ff6602;
}

.admit-file-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .admit-file-name {
    flex: 1;
    font-size: 14px;
  }

  .admit-file-down {
    width: 50px;
    height: 28px;
    margin-left: 10px;
  }
}

::v-deep .van-swipe__indicator {
  background-color: white;
  opacity: 1;
}

::v-deep .van-swipe__indicators {
  background-color: rgba(190, 190, 190, 0.3);
  padding: 2px 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 6px;
}
</style>